import FieldReferenceIdAndKeyDto from "../../../dtos/FieldReferenceIdAndKeyDto";
import PathSynchroMappingCapabilitiesDto from "../../../dtos/pathsynchromodule/PathSynchroMappingCapabilitiesDto";
import TransformationCapabilitiesDto from "../../../dtos/transformationmodule/TransformationCapabilitiesDto";

class GetPathSynchroCapabilitiesResult {
    SourceFields: FieldReferenceIdAndKeyDto[];
    TargetFields: FieldReferenceIdAndKeyDto[];
    MappingsCapabilities: PathSynchroMappingCapabilitiesDto[];
    TransformationsCapabilities: TransformationCapabilitiesDto[]; 

    constructor(sourceFields: FieldReferenceIdAndKeyDto[], targetFields: FieldReferenceIdAndKeyDto[], mappingsCapabilities: PathSynchroMappingCapabilitiesDto[], transformationCapabilities: TransformationCapabilitiesDto[]) {
        this.SourceFields = sourceFields;
        this.TargetFields = targetFields;
        this.MappingsCapabilities = mappingsCapabilities;
        this.TransformationsCapabilities = transformationCapabilities;
    }
}

export default GetPathSynchroCapabilitiesResult;
