abstract class TransformationCapabilitiesDto {
    LogicId: number;
    SourceType: number;
    TargetType: number;
    Label: string;
    Icon: number;
    TransformationCapabilitiesType: string;

    constructor(
        logicId: number,
        sourceType: number,
        targetType: number,
        label: string,
        icon: number,
        transformationCapabilitiesType: string
    ) {
        this.LogicId = logicId;
        this.SourceType = sourceType;
        this.TargetType = targetType;
        this.Label = label;
        this.Icon = icon;
        this.TransformationCapabilitiesType = transformationCapabilitiesType;
    }
}

export default TransformationCapabilitiesDto;
