import React, { useRef, useState } from 'react';

import { Button, ButtonTypes } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';

import TableSelectionForm, { TableSelectionFormHandle } from '../table-selection/TableSelectionForm';

import TableApi from "./../../api/TableApi";

import FieldReferenceLogic from '../../logics/datastructuremodule/FieldReferenceLogic';

import TableSelectionDto from '../../classes/dtos/datastructuremodule/TableSelectionDto';

interface TableSelectionProps {
    DataSourceId: number;
    DatabaseReferenceId: number;
    TableReferenceId: number;
    TableReferenceLabel: string;
    SetTableSelected(tableSelectionDto: TableSelectionDto): void;
}

const TableSelection: React.FC<TableSelectionProps> = ({ DataSourceId, DatabaseReferenceId, TableReferenceId, TableReferenceLabel, SetTableSelected }) => {

    const position = { of: '#root' };

    const [refreshInProgress, setRefreshInProgress] = useState(false);

    const tableSelectionFormHandleRef = useRef<TableSelectionFormHandle>(null);

    const handleSetTableSelected = (tableSelectionDto: TableSelectionDto) => {
        SetTableSelected(tableSelectionDto);
    };

    function selectTable(): void {
        if (tableSelectionFormHandleRef.current !== null) {
            tableSelectionFormHandleRef.current.Open(TableReferenceId);
        }
    };

    const handleRefreshFieldsAsync = async (e: ButtonTypes.ClickEvent) => {
        e.event.stopPropagation();
        if (TableReferenceId > 0) {
            setRefreshInProgress(true);
            var getTablesResult = await TableApi.GetTablesAsync(DataSourceId, DatabaseReferenceId, true);
            if (getTablesResult.IsFailed()) {
                alert(getTablesResult.ErrorMessage);
            }
            var getFieldReferencesResult = await FieldReferenceLogic.GetFieldReferencesAsync(DataSourceId, TableReferenceId, true);
            if (getFieldReferencesResult.IsFailed()) {
                alert(getFieldReferencesResult.ErrorMessage);
            }
            setRefreshInProgress(false);
        }
    };

    return (
        <React.Fragment>

            {DatabaseReferenceId <= 0 ?
                (<div style={{ width: 440, padding: '20px', opacity: 0.30, background: '#1A1A20', borderRadius: 16, justifyContent: 'flex-start', alignItems: 'center', gap: 32, display: 'inline-flex' }}>
                    <div style={{ flexDirection: 'column', justifyContent: 'left', alignItems: 'left', gap: 12, display: 'inline-flex' }}>
                        <div style={{ alignSelf: 'stretch', color: '#E3E3E8', fontSize: 16, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '24px', letterSpacing: 0.32, wordWrap: 'break-word' }}>3. Choose your table</div>
                        <div style={{ color: '#9393A2', fontSize: 12, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '15.60px', letterSpacing: 0.24, wordWrap: 'break-word' }}>No table selected</div>
                    </div>
                    <img src="svgs/plussource.svg" alt="plus" style={{ marginLeft: 'auto' }} />
                </div>) :
                (<div style={{ width: 440, padding: '20px', background: '#1A1A20', borderRadius: 16, border: '1px #3D3D46 solid', justifyContent: 'flex-start', alignItems: 'center', gap: '32px', display: 'inline-flex' }}>
                    <div style={{ flexDirection: 'column', justifyContent: 'left', alignItems: 'left', gap: 12, display: 'inline-flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                            <div style={{ color: '#E3E3E8', fontSize: 16, fontFamily: 'Manrope', fontWeight: '600', lineHeight: '24px', letterSpacing: 0.32, wordWrap: 'break-word' }}>3. Choose your table</div>
                            {TableReferenceId > 0 && <img src="svgs/circle.svg" alt="circle" />}
                        </div>
                        <div style={{ color: '#9393A2', fontSize: 12, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '15.60px', letterSpacing: 0.24, wordWrap: 'break-word', alignSelf: 'flex-start' }}>
                            {TableReferenceId > 0 ? TableReferenceLabel + " selected" : "No table selected"}
                        </div>
                    </div>
                    {TableReferenceId <= 0 ?
                        <img src="svgs/plussource.svg" alt="plus" style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={selectTable} /> :
                        <div style={{ marginLeft: 'auto', cursor: 'pointer', gap: 6, display: 'flex', alignItems: 'center' }} onClick={selectTable} >
                            <Button icon="refresh" style={{ marginRight: '5px' }} onClick={handleRefreshFieldsAsync} />
                            <div style={{ color: '#E3E3E8', fontSize: 14, fontFamily: 'Manrope', fontWeight: '500', lineHeight: '21px', letterSpacing: 0.28, wordWrap: 'break-word' }}>Edit</div>
                            <img src="svgs/edit.svg" alt="circle" />
                        </div>}
                </div>)}
            <TableSelectionForm ref={tableSelectionFormHandleRef} DataSourceId={DataSourceId} DatabaseId={DatabaseReferenceId} SetTableSelected={handleSetTableSelected} />
            <LoadPanel
                position={position}
                shadingColor="rgba(0,0,0,0.4)"
                visible={refreshInProgress}
            />
        </React.Fragment>
    );
}

export default TableSelection;