export default class PathCheckResultDto {

    public static readonly Ok = 0;
    public static readonly BlockingForSave = 1
    public static readonly BlockingForPublish = 2

    constructor(message: string, level: number) {
        this.Message = message;
        this.Level = level;
    }

    Message: string;
    Level: number;
}