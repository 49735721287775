class PathSynchroMappingCapabilityDto {
    SourceFieldId: number;
    TransformationLogicIds: number[];

    constructor(
        sourceFieldId: number,
        transformationLogicIds: number[]
    ) {
        this.SourceFieldId = sourceFieldId;
        this.TransformationLogicIds = transformationLogicIds;
    }
}

export default PathSynchroMappingCapabilityDto;
