import React, { useCallback, useMemo, useState, useImperativeHandle, forwardRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import { Form, GroupItem, ButtonItem } from 'devextreme-react/form';
import { ButtonType } from 'devextreme-react/common';

import './MessageForm.scss';

interface MessageFormProps {
}

export interface MessageFormHandle {
    Open: (message: string, messageType: number) => void;
}

export const InformationMessage = 1;
export const WarningMessage = 2;
export const ErrorMessage = 3;

const MessageForm: React.FC<MessageFormProps> = forwardRef<MessageFormHandle, MessageFormProps>(({ }, ref) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    useImperativeHandle(ref, () => ({
        Open(message: string, messageType: number) {
            setIsOpen(true);
            switch (messageType) {
                case InformationMessage:
                    setTitle("Information");
                    break;
                case WarningMessage:
                    setTitle("Warning");
                    break;
                case ErrorMessage:
                    setTitle("Error");
                    break;
            }
            setMessage(message);
        }
    }));

    const closeDialog = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const popupAttributes = useMemo(() => {
        return {
            id: 'elementId',
            class: 'class-name-popup'
        }
    }, []);

    const close = () => {
        setIsOpen(false);
    }

    const confirmButtonOptions = {
        text: 'Ok',
        type: 'default' as ButtonType,
        useSubmitBehavior: true,
        width: '100%',
        onClick: close
    };

    return (
        <React.Fragment>
            <Popup
                wrapperAttr={popupAttributes}
                visible={isOpen}
                onHiding={closeDialog}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title={title}
                container=".dx-viewport"
                width={400}
                height='auto'
            >
                <Form>
                    <GroupItem>
                        {message}
                    </GroupItem>
                    <GroupItem>
                        <ButtonItem buttonOptions={confirmButtonOptions} />
                    </GroupItem>
                </Form>
            </Popup>
        </React.Fragment>
    );
});

export default MessageForm;