import UserData from "../classes/api/result/accountmodule/UserData";
import { AuthToken } from "./AuthToken";
import UserApi from "./UserApi";

const UserSession = {
    EnsureAsync: async (token: string): Promise<number> => {
        return 0;
    },

    EnsureUserDataAsync: async (): Promise<UserData> => {
        let authSession = await AuthToken.get();
        return (await UserApi.EnsureStandardAccountAsync(authSession.Token));
    }
}

export default UserSession;
