import TableReferenceIdAndKeyDto from "../../../dtos/TableReferenceIdAndKeyDto";

class GetTablesResult {
    Accepted: boolean;
    Status: number;
    LastUpdateDate: Date | null;
    Tables: TableReferenceIdAndKeyDto[];

    constructor(accepted: boolean, status: number, lastUpdateDate: Date | null, tables: TableReferenceIdAndKeyDto[]) {
        this.Accepted = accepted;
        this.Status = status;
        this.LastUpdateDate = lastUpdateDate;
        this.Tables = tables;
    }
}

export default GetTablesResult;
