import FieldReferenceIdAndKeyDto from "../../classes/dtos/FieldReferenceIdAndKeyDto";
import SynchroFieldMappingDto from "../../classes/dtos/pathsynchromodule/SynchroFieldMappingDto";
import TransformationCapabilitiesDto from "../../classes/dtos/transformationmodule/TransformationCapabilitiesDto";
import TransformationOptionsDto from "../../classes/dtos/transformationmodule/TransformationOptionsDto";

export const TransformationLogic = {
    GetOptionsDetails: function (row: SynchroFieldMappingDto, sourceFields: FieldReferenceIdAndKeyDto[], targetFields: FieldReferenceIdAndKeyDto[], transformationsCapabilities: TransformationCapabilitiesDto[]): TransformationOptionsDto | null {

        let sourceField = sourceFields.find((e: FieldReferenceIdAndKeyDto) => e.Id === row.SourceFieldReferenceId) ?? null;
        let targetField = targetFields.find((e: FieldReferenceIdAndKeyDto) => e.Id === row.TargetFieldReferenceId) ?? null;

        if (sourceField === null || targetField === null || row.TransformationType === null) {
            return null;
        }

        return transformationsCapabilities.find((e) => e.LogicId === row.TransformationType && e.TransformationCapabilitiesType === "TransformationOptions") as TransformationOptionsDto ?? null;
    }
}