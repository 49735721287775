import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import GetTablesResult from "../classes/api/result/datastructuremodule/GetTablesResult";
import TableReferenceIdAndKeyDto from "../classes/dtos/TableReferenceIdAndKeyDto";

const TableApi = {

    GetTablesAsync: async function (dataSourceId: number, databaseReferenceId: number, forceUpdate: boolean): Promise<ProcessingResult<GetTablesResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unauthorized");
        }

        const response = await fetch(Settings.api_url + "/Table/GetTables?CustomerId=" + customerId.toString() + "&DataSourceId=" + dataSourceId.toString() + "&DatabaseReferenceId=" + databaseReferenceId.toString() + "&ForceUpdate=" + forceUpdate.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetTablesResult(data.accepted, data.status, data.lastUpdateDate, data.tables.map((e: any) => new TableReferenceIdAndKeyDto(e.id, e.key, e.name, e.type, e.readOnly, e.informativeRowCount))));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    }
};

export default TableApi;