import TransformationCapabilitiesDto from "./TransformationCapabilitiesDto";
import TransformationOptionDto from "./TransformationOptionDto";

class TransformationOptionsDto extends TransformationCapabilitiesDto {
    Options: TransformationOptionDto[];

    constructor(
        logicId: number,
        sourceType: number,
        targetType: number,
        label: string,
        icon: number,
        transformationCapabilitiesType: string,
        options: TransformationOptionDto[]
    ) {
        super(logicId, sourceType, targetType, label, icon, transformationCapabilitiesType);
        this.Options = options;
    }
}

export default TransformationOptionsDto;
